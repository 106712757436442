<template>
    <div>
        <p class="title-head">基础信息</p>
        <van-cell title="昵称" is-link :value="userInfo.nickName ? userInfo.nickName : ''"
                  @click="showNickNameDialog = true"/>
        <van-cell title="头像" is-link>
            <!-- 使用 right-icon 插槽来自定义右侧图标 -->
            <template #right-icon>
                <van-uploader :after-read="afterRead" accept="image/*" multiple :max-count="9">
                    <van-image name="headImage" class="head-image" round fit="cover"
                               :src="userInfo.headimgUrl">
                    </van-image>
                </van-uploader>

                <van-icon name="arrow" style="align-self: center" color="#969799"/>
            </template>
        </van-cell>
        <van-cell title="手机号" :value="userInfo.phone"/>

        <van-field
                readonly
                clickable
                name="picker"
                :value="userInfo.sex == 2 ? '女' : (userInfo.sex == 1 ? '男' : '未知')"
                label="性别"
                input-align="right"
                right-icon="arrow"
                placeholder="点击选择性别"
                @click="showGenderPicker = true"
        />
        <van-popup v-model="showGenderPicker" position="bottom">
            <van-picker
                    show-toolbar
                    :columns="genderColumns"
                    @confirm="onConfirmGender"
                    @cancel="showGenderPicker = false"
            />
        </van-popup>

        <van-field
                readonly
                clickable
                name="picker"
                :value="userInfo.birthday"
                label="生日"
                input-align="right"
                placeholder="点击选择出生年月"
                right-icon="arrow"
                @click="showBirthPicker = true"
        />
        <van-popup v-model="showBirthPicker" position="bottom">
            <van-datetime-picker
                    v-model="currentDate"
                    type="date"
                    title="选择年月日"
                    :min-date="minDate"
                    :max-date="maxDate"
                    @confirm="onConfirmBirth"
                    @cancel="showBirthPicker = false"
            />
        </van-popup>

        <van-field
                readonly
                clickable
                name="area"
                :value="userInfo.province ? userInfo.province + '/' + userInfo.city + '/' + userInfo.country : ''"
                label="目前所在地"
                input-align="right"
                right-icon="arrow"
                placeholder="点击选择省市区"
                @click="showArea = true"
        />
        <van-popup v-model="showArea" position="bottom">
            <van-area
                    :area-list="areaList"
                    @confirm="onConfirmCity"
                    @cancel="showArea = false"
                    :value="userInfo.country_code"
            />
        </van-popup>

        <van-field
                readonly
                clickable
                name="picker"
                :value="userInfo.education"
                label="学历"
                input-align="right"
                placeholder="点击选择学历"
                right-icon="arrow"
                @click="showDegreePicker = true"
        />
        <van-popup v-model="showDegreePicker" position="bottom">
            <van-picker
                    show-toolbar
                    :columns="degreeColumns"
                    @confirm="onConfirmDegree"
                    @cancel="showDegreePicker = false"
            />
        </van-popup>

        <p class="title-head more-info">更多信息</p>

        <van-cell title="真实姓名" is-link :value="userInfo.realname" @click="showRealNameDialog = true"/>

        <van-field
                readonly
                clickable
                name="picker"
                :value="userInfo.income"
                label="家庭收入"
                input-align="right"
                right-icon="arrow"
                placeholder="点击选择收入情况"
                @click="showIncomePicker = true"
        />
        <van-popup v-model="showIncomePicker" position="bottom">
            <van-picker
                    show-toolbar
                    :columns="incomeColumns"
                    @confirm="onConfirmIncome"
                    @cancel="showIncomePicker = false"
            />
        </van-popup>

        <van-field
                readonly
                clickable
                name="picker"
                :value="userInfo.marriage"
                label="婚姻状况"
                input-align="right"
                right-icon="arrow"
                placeholder="点击选择婚姻状况"
                @click="showMarriagePicker = true"
        />
        <van-popup v-model="showMarriagePicker" position="bottom">
            <van-picker
                    show-toolbar
                    :columns="marriageColumns"
                    @confirm="onConfirmMarriage"
                    @cancel="showMarriagePicker = false"
            />
        </van-popup>

        <van-dialog v-model="showNickNameDialog" title="修改昵称" :lock-scroll="true" show-cancel-button
                    :before-close="onBeforeClose" @cancel="cancelNickNameDialog" @confirm="confirmNickName">
            <van-field v-model="nickNameValue" label-width="60px" type="textarea" label="昵称" placeholder="请输入昵称"/>
        </van-dialog>

        <van-dialog v-model="showRealNameDialog" title="真实姓名" :lock-scroll="true" show-cancel-button
                    :before-close="onBeforeClose" @cancel="cancelRealNameDialog" @confirm="confirmRealName">
            <van-field v-model="realNameValue" label-width="60px" type="textarea" label="姓名" placeholder="请输入姓名"/>
        </van-dialog>
    </div>
</template>

<script>
    import {Picker, Popup, Area, Image, DatetimePicker, Toast} from 'vant'
    import Vue from "vue";

    Vue.use(Picker).use(Popup).use(Area).use(Image).use(DatetimePicker);

    import {areaList} from '@vant/area-data';

    export default {
        data() {
            return {
                userInfo: {},

                fileList: [],

                minDate: new Date(1900, 0, 1),
                maxDate: new Date(),
                currentDate: new Date(1990, 0, 1),

                showBirthPicker: false,
                showGenderPicker: false,
                genderColumns: ['男', '女'],

                showDegreePicker: false,
                degreeColumns: ['大专', '本科', '研究生及以上', '其他'],

                cityValue: '',
                showArea: false,
                areaList: areaList, // 数据格式见 Area 组件文档

                showMarriagePicker: false,
                marriageColumns: ['已婚', '未婚', '其他'],

                showIncomePicker: false,
                incomeColumns: ['5000 以下', '5000 - 10000', '10000 - 20000', '20000 以上'],

                showNickNameDialog: false,
                nickNameValue: '',

                showRealNameDialog: false,
                realNameValue: '',
            };
        },
        mounted() {
            this.getProfile()
        },
        methods: {
            getProfile() {
                this.$api.user.profile(
                    {}, res => {
                        this.userInfo = res.data
                    }
                )
            },
            updateProfile(success = ()=>{}) {
                Toast.loading({
                    message: '修改中...',
                    forbidClick: true,
                });
                this.$api.user.updateProfile(this.userInfo, res => {
                    if (res.code == 200) {
                        Toast.clear()
                        success()
                    } else {
                        Toast.fail(res.msg);
                    }
                })
            },
            afterRead(file) {
                const formData = new FormData();
                // 添加文件信息
                formData.append("file", file.file);
                this.$toast.loading({
                    message: '上传中...',
                    forbidClick: true,
                    loadingType: 'spinner',
                    duration: 0
                });
                this.$api.uploadFile.update(formData, (res) => {
                    if (res.code === 200) {
                        this.userInfo.headimgUrl = res.url
                        this.updateProfile()
                        this.$toast.clear()
                    } else {
                        this.$toast({
                            message: res.msg,
                        });
                    }
                }, ()=>{
                    this.$toast({
                        message: "上传失败，请重试",
                    });
                })
            },
            onConfirmBirth(values) {
                this.userInfo.birthday = this.getLocalTime(values)
                this.updateProfile(()=>{
                    this.showBirthPicker = false
                })
            },
            getLocalTime(date) {  // 时间戳 转 时间
                return date.toLocaleDateString().replace(/\//g, "-")/* + " " + date.toTimeString().substr(0, 8)*/;
            },
            onConfirmCity(values) {
                this.userInfo.province = values[0].name
                this.userInfo.city = values[1].name
                this.userInfo.country = values[2].name

                this.userInfo.provinceCode = values[0].code
                this.userInfo.cityCode = values[1].code
                this.userInfo.countryCode = values[2].code

                this.updateProfile(()=>{
                    this.showArea = false;
                })
            },
            onConfirmDegree(values) {
                this.userInfo.education = values
                this.updateProfile(()=>{
                    this.showDegreePicker = false
                })
            },
            onConfirmGender(values, index) {
                this.userInfo.sex = index + 1
                this.updateProfile(() => {
                    this.showGenderPicker = false
                })
            },
            onConfirmIncome(values) {
                this.userInfo.income = values
                this.updateProfile(() => {
                    this.showIncomePicker = false
                })
            },
            onConfirmMarriage(values) {
                this.userInfo.marriage = values
                this.updateProfile(() => {
                    this.showMarriagePicker = false
                })
            },
            onBeforeClose(action, done) {
                return done(false)
            },
            confirmNickName() {
                this.userInfo.nickName = this.nickNameValue
                this.updateProfile(() => {
                    this.showNickNameDialog = false
                })
            },
            cancelNickNameDialog() {
                this.nickNameValue = this.userInfo.nickName
                this.showNickNameDialog = false
            },
            confirmRealName() {
                this.userInfo.realname = this.realNameValue
                this.updateProfile(() => {
                    this.showRealNameDialog = false
                })
            },
            cancelRealNameDialog() {
                this.realNameValue = this.userInfo.realname
                this.showRealNameDialog = false
            }
        },
    };

</script>

<style scoped>
    /deep/ .van-cell__value{
        color: #323233;
    }

    .head-image {
        width: 56px;
        height: 56px;

        align-self: center;
    }

    .title-head {
        font-size: 32px;
        padding: 11px 32px;
    }

    .more-info {
        margin-top: 32px;
    }
</style>